<template>
  <div class="potential">
  <rxNavBar :title="navTitle"></rxNavBar>
    <p class="application_time">申请时间：<span>{{contract.addTime}}</span></p>
    <div class="panel">
      <div class="state">{{contract.approvalStatusStr}}</div>
      <div class="title">
        <p class="van-multi-ellipsis--l2">{{contract.roomDetailedAddress}}</p>
      </div>
      <van-divider />
      <van-row class="info noPadding">
        <van-col span="12">
          <p>合同编号：<span>{{contract.contractCode}}</span></p>
        </van-col>
        <van-col span="12">
          <p>收房年限：<span>{{contract.contractYears}}年</span></p>
        </van-col>
        <van-col span="12">
          <p>业主姓名：<span>{{contract.ownerName}}</span></p>
        </van-col>
        <van-col span="12">
          <p>面积：<span>{{contract.obuiltUpArea}}㎡</span></p>
        </van-col>
        <van-col span="24">
          <p>房间数量：<span>{{contract.bedroomCount}}间</span></p>
        </van-col>
        <van-col span="24">
          <p>申请人：<span>{{contract.approvalStaffName}} {{contract.phone}}</span></p>
        </van-col>
      </van-row>
    </div>
    <div class="panel withMargin" v-if="imagesList.length != 0" >
      <div class="swiper_show"  ref="wrapperBox">
        <div class="content">
          <div class="imgWrap" v-for="(url,i) in imagesList" @click="previewImg(i)">
            <van-image
                    width="100"
                    height="75"
                    fit="contain"
                    radius="10"
                    :src="url"
            />
          </div>
        </div>
        <div class="num">
          {{imagesList.length}}图
        </div>
      </div>
    </div>
    <p class="subtitle">决算详情</p>
    <div class="panel">
      <van-tabs v-model="active"
        line-width="20"
        line-height="5"
        title-active-color="rgb(255,81,45)"
        swipeable
        sticky>
        <van-tab title="硬装验收">
          <!-- 硬装方案 -->
          <van-row class="info noPadding">
            <van-col span="24">
              <p>硬装负责人：<span>{{decorationInfo.hardChargeName}}</span></p>
            </van-col>
            <van-col span="24">
              <p>预计日期：<span>{{decorationInfo.hardAppointmentBeginTime}}至{{decorationInfo.hardAppointmentEndTime}}</span></p>
            </van-col>
            <van-col span="24">
              <p>实际日期：<span>{{decorationInfo.hardBeginTime}}至{{decorationInfo.hardEndTime}}</span></p>
            </van-col>
            <van-col span="24">
              <p>首期装修款：<span>{{decorationInfo.firstHardfee}}元</span></p>
            </van-col>
            <van-col span="24">
              <p>实际费用：<span>{{decorationInfo.hardRealFee}}元</span></p>
            </van-col>
            <van-col span="24">
              <p >应付剩余：<span class="total_color">{{Number(Number(decorationInfo.hardFinalFee) - Number(decorationInfo.firstHardfee)).toFixed(2)}}元</span></p>
            </van-col>
            <van-col span="24">
              <p >决算合计费用：<span class="total_color">{{decorationInfo.hardFinalFee}}元</span></p>
            </van-col>
          </van-row>
          <van-divider />
          <div class="t_table">
            <van-row>
              <van-col span="1" class="t_header"></van-col>
              <van-col span="9" class="t_header">项目名称</van-col>
              <van-col span="4" class="t_header">数量</van-col>
              <van-col span="9" class="t_header">实际费用（预计费用）</van-col>
              <van-col span="1" class="t_header"></van-col>
            </van-row>
            <div class="t_body" v-for="body in hardwareList" :key="body.id">
              <van-row>
                <span class="diamod"></span>
                <span class="t_title" style="font-size: 0.32rem">{{body.goodsAllocationClassName}}</span>
<!--                <van-col span="24" class="t_title">{{body.goodsAllocationClassName}}</van-col>-->
                <van-row v-for="item in body.childrenList" :key="item.id" class="itemFontStyle">
                  <van-col span="1" class="t_td"></van-col>
                  <van-col span="9" class="t_td">{{item.itemName}}</van-col>
                  <van-col span="4" class="t_td_1">{{item.totalCount}}</van-col>
                  <van-col span="9" class="t_td">{{Number(item.realMoney).toFixed(2)}}元<span style="color:rgb(136,136,136) ">（{{item.estimateMoney}}）</span></van-col>
                  <van-col span="1" class="t_td"></van-col>
                </van-row>
              </van-row>
            </div>

          </div>
        </van-tab>
        <van-tab title="软装验收">
            <van-row class="info noPadding">
<!--                <van-col span="12">-->
<!--                <p>首期占比：<span>{{decorationInfo.proportion}}%</span></p>-->
<!--                </van-col>-->
<!--                <van-col span="12">-->
<!--                  <p>预计时长：<span>￥{{decorationInfo.softAppointmentTimeLong}}天</span></p>-->
<!--                </van-col>-->
<!--                <van-col span="12">-->
<!--                  <p>预计费用：<span>￥{{decorationInfo.softBudgetFee}}</span></p>-->
<!--                </van-col>-->
<!--                <van-col span="24">-->
<!--                  <p>预计日期：<span>{{decorationInfo.softAppointmentBeginTime}}至{{decorationInfo.softAppointmentEndTime}}</span></p>-->
<!--                </van-col>-->
<!--                <van-col span="24">-->
<!--                  <p>软装负责人：<span>{{decorationInfo.softChargeName}}</span></p>-->
<!--                </van-col>-->
              <van-col span="24">
                <p>软装负责人：<span>{{decorationInfo.softChargeName}}</span></p>
              </van-col>
              <van-col span="24">
                <p>预计日期：<span>{{decorationInfo.softAppointmentBeginTime}}至{{decorationInfo.softAppointmentEndTime}}</span></p>
              </van-col>
              <van-col span="24">
                <p>实际日期：<span>{{decorationInfo.softBeginTime}}至{{decorationInfo.softEndTime}}</span></p>
              </van-col>
              <van-col span="24">
                <p>首期装修款：<span>{{decorationInfo.firstSoftfee}}元</span></p>
              </van-col>
              <van-col span="24">
                <p>实际费用：<span>{{decorationInfo.softRealFee}}元</span></p>
              </van-col>
              <van-col span="24">
                <p >应付剩余sss：<span class="total_color">{{Number(Number(decorationInfo.softFinalFee) - Number(decorationInfo.firstSoftfee)).toFixed(2)}}元</span></p>
              </van-col>
              <van-col span="24">
                <p >决算合计费用：<span class="total_color">{{decorationInfo.softFinalFee}}元</span></p>
              </van-col>
          </van-row>
          <van-divider />
          <div class="t_table">
            <van-row>
              <van-col span="1" class="t_header"></van-col>
              <van-col span="10" class="t_header">项目名称</van-col>
              <van-col span="4" class="t_header">分类</van-col>
              <van-col span="4" class="t_header">数量</van-col>
              <van-col span="4" class="t_header">预计费用</van-col>
              <van-col span="1" class="t_header"></van-col>
            </van-row>
            <div class="t_body" v-for="body in softwareList" :key="body.id">
              <van-row>
                <span class="diamod"></span>
                <span class="t_title" style="font-size: 0.32rem">{{body.roomName}}</span>
<!--                <van-col span="24" class="t_title">{{body.roomName}}</van-col>-->
                <van-row v-for="item in body.childrenList" :key="item.id" class="itemFontStyle">
                  <van-col span="1" class="t_td"></van-col>
                  <van-col span="10" class="t_td">{{item.itemName}}</van-col>
                  <van-col span="4" class="t_td">{{item.goodsAllocationClassName}}</van-col>
                  <van-col span="4" class="t_td_1">{{item.totalCount}}</van-col>
                  <van-col span="4" class="t_td">{{item.estimateMoney ? item.estimateMoney+'元' :''}}</van-col>
                  <van-col span="1" class="t_td"></van-col>
                </van-row>
              </van-row>
            </div>

          </div>
        </van-tab>
      </van-tabs>
    </div>
    <div class="panel withMargin" style="margin-bottom: 0.5rem">
      <van-row>
        <van-col span="24" class="info">
          <p>预计总装修款：<span class="floatR">{{decorationInfo.budgetFee}}元</span></p>
          <p>实际总装修款：<span class="floatR">{{decorationInfo.totalRealFee}}元</span></p>
          <!--后台可修改，目前app端直接使用硬装、软装决算合计费用计算-->
          <p>决算总装修款：<span class="floatR">{{decorationInfo.hardFinalFee + decorationInfo.softFinalFee}}元</span></p>
        </van-col>
      </van-row>
      <van-divider style="marginTop:0"/>
      <van-row>
        <van-col span="24" class="info">
          <p class="total_color">剩余应付总费用：<span class="floatR total_color">{{decorationInfo.surplusPrice}}元</span></p>
        </van-col>
      </van-row>

    </div>
    <div v-if="auditOrCheck">
      <div class="panel radio_body withMargin newMarginStyle1" :class="{'newMarginStyle2':isPass=='0'}">
        <p style="marginBottom:15px">
          <span :class="isChoose ? 'dot is_dot' : 'dot'"></span>
          审核结果
        </p>
        <div class="choose">
          <div :class="isPass == 1?'choose_btn pass': 'choose_btn'" @click="multipleChoice(1)">通过</div>
          <div class="line"></div>
          <div :class="isPass == 0?'choose_btn reject': 'choose_btn'" @click="multipleChoice(0)">驳回</div>
        </div>
      </div>

      <div id="addressPanel" class="part-inputpart-row" v-show="isReject" @click="isReasonShow = !isReasonShow">
        <span class="maker" :class="{'orangeColor':isReasonSelect}" >• </span>
        <span class="part-inputpart-row-header">原因</span>
        <span class="content-divide">|</span>
        <span class="part-inputpart-row-graytext" :class="isReasonSelect ? 'part-inputpart-row-normaltext':'' ">{{rejectReason.dictionaryTitle}}</span>
        <img class="part-inputpart-row-right" :class=" isReasonShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' " src="../../assets/images/triangle.png">
      </div>
<!--      <div>-->
<!--        <div class="part-inputpart part-inputpart-dropDownList" v-if="isReasonShow">-->
<!--          <div class="part-inputpart-dropDownList-option" :class="item==rejectReason ? 'part-inputpart-dropDownList-option-selected' : '' "-->
<!--               v-for="(item,index) in refuseReasonList" :key="index" @click="selectAddressOption($event,index)">{{ item.dictionaryTitle}}</div>-->
<!--        </div>-->
<!--      </div>-->


      <div class="panel">
        <p>审批意见</p>
        <div class="text_body">
          <van-field
                  v-model="opinion"
                  autosize
                  type="textarea"
                  class="mes_body"
          />
        </div>
      </div>
      <div class="sub_btn">
        <van-button :class="(isChoose&&isPass=='1')||(isPass=='0'&&isReasonSelect)?'sub_button':'sub_button not_choose'" :disabled="!isChoose||(isChoose&&isPass=='0'&&!isReasonSelect)" size="large" @click="save">保存</van-button>
      </div>
    </div>
    <van-popup v-model="isReasonShow" position="bottom">
      <van-picker
          show-toolbar
          :columns="refuseReasonList"
          @cancel="isReasonShow = false"
          :default-index="refuseReasonIndex"
          value-key="dictionaryTitle"
          @confirm="selectAddressOption"
      />
    </van-popup>
  </div>
</template>
<script>
import {
  NavBar,
  Tab,
  Tabs,
  Row,
  Col,
  DropdownMenu,
  DropdownItem,
  Tag,
  Button,
  Popup,
  Field,
  Divider,
  Image as VanImage,
  ImagePreview,
  Picker
} from 'vant'
import {decorationAgree, decorationDetailInit, decorationRefuse} from "../../getData/getData";
import {responseUtil} from "../../libs/rongxunUtil";
import rxNavBar from "../../components/rongxun/rx-navBar/navBar";
import BScroll from "better-scroll";
export default {
  data () {
    return {
      refuseReasonIndex: '',
      //头部标题
      navTitle:'装修决算申请',
      //是否隐藏审核部分
      auditOrCheck:true,
      //通过or驳回
      istype:2,
      isReject:false,
      isReasonSelect:false,
      rejectReason:'请选择',
      isReasonShow:false,
      //拒绝理由
      refuseReasonList: {
      },
      approvalHistoryInfoList:{},  //审核历史信息
      hardwareInfo:{
        proportion:40,
        downPayment:3400,
        duration:3,
        planMoney:3400,
        planDate:'2020-02-02',
        planEnd:'2020-03-05',
        principal:'张工 183322322322',
      },
      softwareList:[
      ],

      isChoose:false,
      isPass:null,
      opinion:'',
      active:0,
      title:'',
      contract:{

      },
      hardwareList:{

      },
      imagesList:[
      ],
    }
  },
  name: 'ReviewOfDecorationScheme',
  components: {
    [NavBar.name]: NavBar,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Row.name]: Row,
    [Col.name]: Col,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Tag.name]: Tag,
    [Button.name]: Button,
    [Popup.name]:Popup ,
    [Field.name]:Field,
    [Divider.name]:Divider ,
    [VanImage.name]:VanImage,
    [Picker.name]: Picker,
    [ImagePreview.Component.name]: ImagePreview.Component,
    rxNavBar
  },
  mounted() {
    this.initData();
  },
  methods:{
    multipleChoice(isPass){
      this.isChoose = true;
      this.isPass = isPass
      if(isPass==0){
        this.isReject = true
        this.istype = 0
      }
      if(isPass==1){
        this.istype = 1
        this.isReject = false
      }
    },
    //装修决算审批初始化
    initData(){
      let that = this;
      //如果是点击查看进入本页面，则隐藏审核按钮
      if(this.$route.params.afterRentStatus){
        that.auditOrCheck = false
        that.navTitle = '查看详情'
      }
      let initData = {};
      initData.type = '10';
      initData.id = this.$route.params.id;
      // initData.id = '332';

      decorationDetailInit(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          console.log(response.data.data);
          // debugger
          that.contract = response.data.data.item
          that.decorationInfo = response.data.data.renovationMap
          that.hardwareList = response.data.data.goodsMap.selectedHardList
          that.softwareList = response.data.data.goodsMap.selectedSoftList
          that.refuseReasonList = response.data.data.refuseReasonList
          that.approvalHistoryInfoList = response.data.data.approvalHistoryInfoList
          let list = response.data.data.picList
          if(list){
            for(let i=0;i<list.length;i++){
              that.imagesList.push(list[i].path)
            }
          }
          that.$nextTick(() => {
            if (!that.scroll) {
              that.scroll = new BScroll(that.$refs.wrapperBox, {
                scrollX: true,
                eventPassthrough: 'vertical'
              })
            }
          })
          //计算剩余应付总费用
          that.decorationInfo.surplusPrice = that.decorationInfo.hardFinalFee - that.decorationInfo.firstHardfee + that.decorationInfo.softFinalFee - that.decorationInfo.firstSoftfee
        })
      })
    },
    //返回上一层
    leftReturn(){
      this.$router.go(-1);
    },
    previewImg(start){
      ImagePreview(
              {
                images: this.imagesList,
                startPosition: start,
                onClose() {
                  // do something
                },
              }
      )
    },

    clickAddressShow() {
      this.isReasonShow = !this.isReasonShow;
      var panel = document.getElementById('addressPanel')
      document.addEventListener('click',e =>{
        if(!panel.contains(e.target)){
          this.isReasonShow = false
        }
      })
    },

    selectAddressOption(item,index){
      this.rejectReason = item
      this.refuseReasonIndex = index
      this.isReasonShow = false
      this.isReasonSelect = true
      this.refuseId= this.refuseReasonList[index].id
    },
    //保存
    save(){
      var that = this;
      var func;
      var data = {}
      debugger
      //通过的接口
      if(this.istype == "1"){
        func = decorationAgree;
      }
      //拒绝的接口
      if(this.istype == "0"){
        func = decorationRefuse;
        //拒绝理由id
        data.refuseReason_id=that.refuseId;
      }
      data.id = that.contract.id
      data.staff_id = that.contract.approvalApplicantStaff_id
      data.approvalOpinion =that.opinion
      data.rtid =  that.contract.rtid
      data.type = "10"
      func(data).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          console.log(response.data)
          responseUtil.alertMsg(that,response.data.msg)
          that.leftReturn();
        })
      })
    },
  }
}

</script>
<style lang="less" scoped>
@redius:8px;
@border:1px solid rgb(244, 244, 244);

.diamod {
  width: 8px;
  height: 8px;
  background-color: #3B98FF;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  margin: 17px 7px 0 18px;
  display: inline-block;
}

.t_title {
  background-color: white;
  color:#33ADFF;
  text-align: center;
  font-weight: 700;
  font-size: 12px;
  height: 20px;
}


.potential{
  width: 375px;
  background-color: rgb(250,250,250);
  font-size: 12px;
  padding: 0 15px;
  box-sizing: border-box;
  overflow: hidden;
  .sub_btn{
    margin: 30px 0 70px;
    .sub_button{
      border-radius: @redius;
      color: rgb(255,255,255);
      border: none;
      background: linear-gradient(rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
      background: -webkit-linear-gradient(left, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
      /* Safari 5.1 - 6.0 */
      background: -o-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
      /* Opera 11.1 - 12.0 */
      background: -moz-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
      /* Firefox 3.6 - 15 */
      background: linear-gradient(to right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
      &.not_choose{
        background: none;
        background-color: rgba(184, 184, 184, 0.2);
      }
    }
  }
  p{
    margin:0px;
  }
  .application_time{
      text-align: right;
      width: 345px;
      font-size: 12px;
      color: rgb(136,136,136);
      padding: 10px 0 5px;
  }
  .subtitle{
    padding: 5px 0px;
    color: rgb(244,99,76);
    margin-top: 25px;
    font-weight: 600;
  }
  .panel{
    width: 345px;
    background-color: rgb(255,255,255);
    border-radius: @redius;
    position: relative;
    font-size: 15px;
    text-align: left;
    overflow: hidden;
    &.withMargin{
      margin-top: 25px;
    }
    & > p{
      padding: 15px 15px 0px;
      font-weight: bolder;
    }
    & > p > span{
      font-weight: normal;
      color :rgb(82,82,82);
    }
    .state{
      position: absolute;
      top: 0;
      left: 0;
      font-size:20px;
      font-weight: bold;
      height:32px;
      line-height: 32px;
      width: 60px;
      display: block;
      background: linear-gradient(rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
      background: -webkit-linear-gradient(left, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
      /* Safari 5.1 - 6.0 */
      background: -o-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
      /* Opera 11.1 - 12.0 */
      background: -moz-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
      /* Firefox 3.6 - 15 */
      background: linear-gradient(to right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
      border-top-left-radius: 16px;
      border-bottom-right-radius: 16px;
      color: rgb(255,255,255);
      text-align: center;
      transform: scale(0.5);
      transform-origin: 0% 0%;
      font-family: sans-serif;
      padding: 0 10px;
    }
    .title{
      width: 315px;
      padding: 0 15px;
      font-weight: bolder;
      color: rgb(0,0,0);
      font-size: 15px;
      padding-top: 20px;
      span{
        font-weight: normal;
        color:rgba(153, 153, 153, 1);
      }
    }
    .describe{
      padding-bottom: 15px;
      padding-top: 10px;
      color: rgb(82,82,82);
      font-weight: normal;
    }
    .info{
      padding: 15px;
      &.noPadding{
        padding: 0 15px;
      }
      p{
        margin: 4px 0;
        font-weight: bolder;
        font-size: 0.3733rem;
        span{
          font-weight: normal;
          color: rgb(82,82,82);
        }
      }
      .floatR{
        float: right;
      }
      .total_color{
        color: rgba(255, 93, 59, 1);
      }
    }
    .text_body{
      padding: 15px;
      .mes_body{
        border-radius: @redius;
        background-color: rgb(250,250,250);
      }
    }
  }
  // 单选框
  .radio_body{
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
    .dot{
      display: inline-block;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: rgb(136,136,136);
      vertical-align: middle;
      margin-right: 6px;
      &.is_dot{
      background-color: rgb(251,91,64);
    }
    }
    .line{
      width: 2px;
      height: 12px;
      background-color: rgb(250,250,250);
    }
    .choose{
      margin-right: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .choose_btn{
        padding: 5px;
        margin: 10px;
        color: rgb(136,136,136);
        cursor: pointer;
        &.pass{
          color: rgb(251,91,64);
          font-weight: bolder;
        }
        &.reject{
          color: red;
          font-weight: bolder;
        }
      }
    }
  }

  // 循环表单
  .t_table{
    .t_header{
      text-align: left;
      color: rgb(136,136,136);
      font-weight: bolder;
      font-size: 10px;
      height: 20px;
      line-height: 21px;
      background-color: rgba(243, 249, 255, 1);
    }
    .t_title{
      /*background-color: rgb(244,249,255);*/
      color: rgb(39,135,248);
      text-align: center;
      font-weight: 400;
      font-size: 14px;
    }
    .t_td{
      text-align: left;
      height: 40px;
      line-height: 40px;
    }
    .itemFontStyle {
      font-size: 13px;
    }
    .t_td_1{
      text-align: left;
      height: 40px;
      line-height: 40px;
      padding-left: 10px;
    }
  }
}

.part-inputpart {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  font-size: 15px;
  border-radius: 8px;
}


.part-inputpart-dropDownList{
  width: 90%;
  position: absolute;
  left: 19px;
  z-index: 99;
  margin-top: -17px;

}

.part-inputpart-dropDownList-option{
  padding: 0;
  margin: 0;
  text-align: center;
  line-height: 30px;
  background-color: #f5f5f5;
  font-size: 12px;
  border-bottom: 1px white solid;
  color: #999999;
}

.part-inputpart-dropDownList-option-selected{
  color: #ff5d3b;
}

.part-inputpart-row-right-upArrow{
  width: 9px;
  transform: rotateX(180deg);
}

.part-inputpart-row-header{
  font-weight: bold;
  font-size: 15px;
  width: 13%;
}


.part-inputpart-row-graytext {
  color: #d8d8d8;
  width: 100%;
  font-size: 15px;
}

.return-audit{
  font-size: 13px;
  color: red;
  margin: 26px 18px 4px;
  padding: 0;

}

.part-inputpart-row-normaltext{
  color: black;
}
.part-inputpart-row-right{
  float: right;
  margin-right: 10px;
}
.part-inputpart-row-right-downArrow{
  width: 9px;
}
.part-inputpart-row {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  background-color: white;
  border-radius: 8px;
  width:345px;
  margin: 14px auto;
}
.maker{
  color: #999999;
  float: left;
  padding-left: 14px;
  padding-right: 8px;
  font-size: 28px;
}
.orangeColor{
  color: #ff3c00;
}
.content-divide {
  font-size: 14px;
  color: #efefef;
  margin:0 10px;
}
.content-divide-small {
  font-size: 14px;
  // color: #9a9a9a;
  margin:0 5px;
}
  .newMarginStyle1{
    margin-bottom: 14px;
  }
  .newMarginStyle2{
    margin-bottom: 0px;
  }
// 图片墙
.swiper_show{
  width: 325px;
  position: relative;
  margin: 15px 10px;
  height: 75px;
  .content{
    position: absolute;
    display: flex;
    justify-content: space-around;
    left: 0px;
    .imgWrap{
      margin-right: 10px;
    }
  }
  .num{
    position: absolute;
    left: 4px;
    top: 6px;
    font-size: 10px;
    background-color: rgb(0,0,0);
    opacity: .5;
    color: rgb(255,255,255);
    padding:2px 6px;
    display: inline-block;
    border-radius: 10px;
    font-weight: bolder;
    line-height: 10px;
  }
}
</style>
